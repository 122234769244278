<template>
  <div class="student-container">
    <!-- header -->
    <el-card>
      <el-row class="header-search-bar">
        <el-col :span="6">
          <el-input
            placeholder="姓名、录取编号"
            class="input-with-select"
            clearable
            v-model="queryInfo.keyword"
            @clear="getStudentList('search')"
            @keyup.enter.native="getStudentList('search')"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getStudentList('search')"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <header-select
            :selectItem="['grade', 'campus', 'college', 'major']"
            :selectItemAll="['college', 'campus', 'major']"
            @search="selectSearchHandle"
          ></header-select>
        </el-col>
        <el-col class="header-button" :span="24">
          <el-button type="success" size="small" @click="exportStudentInfo">导出缴费名单</el-button>
        </el-col>
      </el-row>
    </el-card>
    <!-- body -->
    <el-card>
      <el-table :data="studentList" style="width: 100%" border stripe>
        <el-table-column type="index" label="ID" width="50"></el-table-column>
        <el-table-column prop="examineNo" label="录取编号" width="120">
        </el-table-column>
        <el-table-column prop="grade" label="年级" width="80">
        </el-table-column>
        <el-table-column prop="stuName" label="姓名" width="120">
        </el-table-column>
        <el-table-column label="学籍状态" width="120">
          <template v-slot="scoped">
            <span v-for="(item, index) in stuStatus" :key="index">
              <span v-if="Number(scoped.row.stuStatus) === Number(item.paramValue)">{{ item.paramName }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="sexType" label="性别" width="80">
          <template v-slot="scoped">
            <span v-if="Number(scoped.row.sexType) === 1">男</span>
            <span v-else>女</span>
          </template>
        </el-table-column>
        <el-table-column prop="campusName" label="校区" width="100">
        </el-table-column>
        <el-table-column prop="collegeName" label="院系" width="150">
        </el-table-column>
        <el-table-column prop="majorName" label="专业" width="150">
        </el-table-column>
        <el-table-column prop="className" label="班级" width="150">
          <template v-slot="{row}">
            {{ row.className || '未绑定' }}
          </template>
        </el-table-column>
        <el-table-column prop="apartmentName" label="宿舍" width="150">
          <template v-slot="scoped">
            <span v-if="scoped.row.dormitoryName && scoped.row.dormitoryBedNo"
            >{{ scoped.row.dormitoryName }}{{ scoped.row.roomName }}室{{
                scoped.row.dormitoryBedNo
              }}号床</span
            >
            <span v-else>未分配</span>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注">
        </el-table-column>
        <!-- <el-table-column label="操作" width="100" fixed="right">
          <template v-slot="scoped">
            <el-button
              type="danger"
              size="mini"
              @click="deleteStudent(scoped.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
    </el-card>
    <base-pagination
      :total="total"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></base-pagination>
  </div>
</template>

<script>
import { getStudentList, deleteStudent } from '@/api/student'

export default {
  data () {
    return {
      addData: {
        id: '',
        title: '',
        isOpen: false,
        type: ''
      },
      setData: {
        isOpen: false,
        id: ''
      },
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        keyword: '',
        stuStatus: 5, // 已缴费未报到
        campusId: null,
        grade: null,
        collegeId: null,
        majorId: null
      },
      stuStatus: this.$store.state.systemParam.stuStatus,
      studentList: [],
      stuIds: [],
      total: 0
    }
  },
  methods: {
    // 头部下拉事件
    selectSearchHandle (data) {
      this.queryInfo.grade = data.grade
      this.queryInfo.collegeId = data.collegeId
      this.queryInfo.majorId = data.majorId
      this.queryInfo.campusId = data.campusId
      this.getStudentList()
    },
    // 获取学生列表
    getStudentList (type) {
      if (type === 'search') {
        this.queryInfo.pageNum = 1
      }
      getStudentList(this.queryInfo).then((res) => {
        this.studentList = res.data.list
        this.total = res.data.total
      })
    },
    // 添加与编辑学生
    createStudent (type, id) {
      this.addData.isOpen = true
      this.addData.type = type
      if (type === 'create') {
        this.addData.id = ''
        this.addData.title = '添加学生'
      } else {
        this.addData.id = id
        this.addData.title = '编辑学生'
      }
    },
    /* 打开分配学籍 */
    openSetStudentStatus (id) {
      this.setData = {
        isOpen: true,
        id
      }
    },
    // 改变分页条目
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getStudentList()
    },
    // 改变页数
    handleCurrentChange (page) {
      this.queryInfo.pageNum = page
      this.getStudentList()
    },
    // 导出已缴费名单
    exportStudentInfo () {
      this.$http.exportExcelHttp.exportStudentInfo({
        stuStatus: 5
      }, '缴费名单').then(res => {

      })
    },
    // 删除学生
    deleteStudent (id) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteStudent(id).then((res) => {
            this.$message.success('删除成功!')
            this.getStudentList()
          })
        })
        .catch(() => {
          this.$message('已取消删除')
        })
    },
    // 监听成功添加与编辑
    successHandle () {
      this.getStudentList()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
